import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/react"
import i18n from "../../config/i18n"
import { colors } from "../styles/styles"
import Header from "./header"
import Footer from "./footer"
import "../styles/styles.scss"
import "../styles/layout.css"
import { graphql } from 'gatsby'
import { navigate, useStaticQuery } from "gatsby"
import { Crisp } from "crisp-sdk-web"


const LocaleContext = React.createContext()


const Layout = ({
  data,
  children,
  location,
  pageContext: {
    uid,
    locale,
    alternateLanguages = [],
  },
}) => {
  const headerAndFooterData = useStaticQuery(graphql`
    query headersAndFooters {
      allFile(
        filter: { extension: { eq: "svg" }, name: { regex: "/flag/" } }
      ) {
        nodes {
          name
          publicURL
        }
      }
      allPrismicHeader {
        nodes {
          data {
            alert_message {
              text
              richText
              html
            }
            header_call_to_action {
              header_call_to_action_id
              header_call_to_action_name
              header_call_to_action_link {
                uid
              }
            }
            header_social_title {
              text
              richText
              html
            }
            header_social {
              header_social_id
              header_social_link {
                url
              }
            }
            header_logo_image {
              gatsbyImageData
              url
              alt
              dimensions {
                width
                height
              }
            }
            body{
              __typename
              ... on PrismicHeaderDataBodyMenuElement{
                slice_type
                slice_label
                primary{
                  is_hidden
                  menu_element_id
                  menu_element_name
                  menu_element_icon {
                    gatsbyImageData
                    url
                    alt
                    dimensions {
                      width
                      height
                    }
                  }
                  menu_element_icon_hover {        
                    gatsbyImageData
                    url
                    alt
                    dimensions {
                      width
                      height
                    }
                  }
                  menu_element_link{
                    link_type
                    uid
                    url
                  }
                }
              }
              ... on PrismicHeaderDataBodyMenuElementWithSubelements{
                slice_type
                primary {
                  is_hidden
                  menu_group_id
                  menu_group_name
                }
                items {
                  menu_group_element_id
                  menu_group_element_name
                  menu_group_element_link{
                    link_type
                    uid
                    url
                  }
                }
              }
              ... on PrismicHeaderDataBodyMenuGroupWithSubgroup {
                slice_type
                primary {
                  menu_group_with_sub_group_master_title {
                    text
                    richText
                    html
                  }
                  menu_group_with_sub_group_id {
                    text
                    richText
                    html
                  }
                  menu_group_with_sub_group_name {
                    text
                    richText
                    html
                  }
                  is_hidden
                }
                items {
                  menu_group_with_sub_group_element_id {
                    text
                    richText
                    html
                  }
                  menu_group_with_sub_group_element_name {
                    text
                    richText
                    html
                  }
                  menu_group_with_sub_group_element_link{
                    uid
                  }
                }
              }
            }
          }
          lang
        }
      }
      
      allPrismicFooter {
        nodes {
          id
          lang
          data {
            copyright {
              text
              richText
              html
            }
            last_update {
              text
              richText
              html
            }
            column_title {
              text
              richText
              html
            }
            link_app_1{
              url
            }
            image_app_1 {        
              gatsbyImageData
              url
              alt
              dimensions {
                width
                height
              }
            }
            link_app_2 {
              url
            }
            image_app_2 {        
              gatsbyImageData
              url
              alt
              dimensions {
                width
                height
              }
            }
            body {
              ... on PrismicFooterDataBodyCategory {
                primary {
                  category_id
                  category_name {
                    text
                    richText
                    html
                  }
                }
                items {
                  footer_element_id
                  footer_element_name
                  footer_element_link {
                    link_type
                    uid
                    url
                    target
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  useEffect(() => {
    Crisp.configure("b4603124-8f9f-4f29-8e31-e9e69f95b61c");
  }, []);
  return (
    <>
      <LocaleContext.Provider
        value={{ locale, i18n, location, alternateLanguages }}
      >
        <Header allHeadersAndFooters={headerAndFooterData} />
        <main css={main}>{children}</main>
        <Footer allHeadersAndFooters={headerAndFooterData} />
      </LocaleContext.Provider>
    </>
  )

}


Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export { Layout, LocaleContext }


const main = css`
  *:focus {
    outline: none !important;
  }
  .title {
    line-height: 1.5;
  }
  .is-theme1 {
    background: ${colors.seashellPeach};
  }
  .is-theme2 {
    background: ${colors.zircon};
  }
  .is-themeGradientV {
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(239, 243, 255, 0.62) 50%,
      rgba(255, 255, 255, 1) 50%
    );
  }
  .is-themeLightPink {
    background-color: #fff9f9;
  }
  .hasRichText {
    a {
      text-decoration: underline;
      color: ${colors.blue};
      &:hover {
        color: ${colors.orange};
      }
    }
    ul,
    ol {
      li {
        list-style: square inside none;
        margin-bottom: 1.5rem;
      }
    }
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 500;
      margin-top: 1rem;
      color: ${colors.orange};
    }
    h2 {
      font-size: 2.5rem;
    }
    h3 {
      font-size: 2rem;
    }
    h4 {
      font-size: 1.5rem;
    }
    h5 {
      font-size: 1.25rem;
    }
    h6 {
      font-size: 1rem;
    }
    .gatsby-image-wrapper {
      margin-bottom: 1.5rem;
    }
    p {
      line-height: 1.5;
      margin-bottom: 1rem;
    }
    .column {
      padding: 0.75rem;
    }
  }

  @media (max-width: 767px) {
    .hasRichText {
      h2 {
        font-size: 1.5rem;
      }
      h3 {
        font-size: 1.25rem;
      }
      h4 {
        font-size: 1.1rem;
      }
      h5 {
        font-size: 1rem;
      }
      h6 {
        font-size: 0.9rem;
      }
    }
  }

  .has-text-weight-extralight {
    font-weight: 200;
  }

  .btn-shadow {
    box-shadow: 0 12px 14px ${colors.blueLightOne};
  }

  .is-align-items-center {
    align-items: center;
  }

  .is-margin-0-auto {
    margin: 0 auto;
  }

  .has-background-blue {
    background-color: ${colors.blue};
  }
  .has-opacity {
    opacity: 60%;
  }

  .button__gradient-border {
    border: solid 1px transparent;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      ${colors.orangeGradient};
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset;
  }

  .button__gradient-border:hover,
  a.has-background-white.button__gradient-border.has-text-primary:hover,
  .button__gradient-border.is-active {
    box-shadow: none;
    color: #fff !important;
  }

  .form__outline {
    .has-icons-left {
      input {
        padding-left: 2.5em;
      }
    }

    .input,
    textarea,
    select {
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-radius: 0;
      border-bottom: 1px solid ${colors.borderForm};
      background: transparent;
      box-shadow: none;
      padding-left: 0;
      font-weight: 300;
    }

    .control.has-icons-left .icon {
      color: ${colors.blue};
    }

    select,
    .select {
      width: 100%;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    background: white;
    padding: 20px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    box-shadow: 0 12px 14px rgba(87, 105, 164, 0.1);
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 15px;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after,
  .swiper-button-next-client:after,
  .swiper-button-prev-client:after {
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#fd7543),
      to(#de4e4e)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .grecaptcha-badge {
    visibility: hidden;
  }
`
const mainB = css `
.mainb{
  margin-top:auto;
}

`