import React, { useState } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import locales from '../../config/i18n';
import { LocaleContext } from './layout';

import { css } from '@emotion/react';
import { colors } from '../styles/styles';
import i18n from '../../config/i18n';

export default function LangSwitcher({ flags }) {
  let { locale, location, alternateLanguages } =
    React.useContext(LocaleContext);
  locale = locale ? locale : 'en-gb'; // fix: 404 doesnt know the locale
  const [isOpenLangSelect, setIsOpenLangSelect] = useState(false);
  const altPath = alternateLanguages.reduce(
    (alternateLanguage, key) => ({
      ...alternateLanguage,
      [key.lang]: { uid: key.uid },
    }),
    {}
  );
  const isHome =
    location.pathname === '/' ||
    location.pathname === `/${i18n[locale].path}` ||
    location.pathname === `/${i18n[locale].path}/`
      ? true
      : false;
  return (
    <div
      className="dropdown dropdown__lang is-active"
      css={langSwitcherCss}
      onMouseEnter={() => setIsOpenLangSelect(true)}
      onMouseLeave={() => setIsOpenLangSelect(false)}
    >
      <div className="dropdown-trigger">
        <button className="activeLang button is-rounded">
          {flags.nodes
            .filter((node) => node.name === `flag-${locale}`)
            .map((loc) => (
              <img
                key={locale}
                src={loc.publicURL}
                alt={locales[locale].langName}
                className="flag"
              />
            ))}
          <span>{locales[locale].langName}</span>
          <i className="icon is-small icon-chevron_down" />
        </button>
      </div>
      <div
        className={`allLang dropdown-menu ${
          !isOpenLangSelect ? 'is-hidden' : ''
        }`}
        id="dropdown-menu"
        role="menu"
      >
        <div className="dropdown-content">
          {Object.keys(locales)
            .filter((lang) => locales[lang].locale !== locales[locale].locale)
            .map((lang) => {
              const localizedPath = `/${locales[lang].path}`;

              //Gestion of exeption
              const isBlog = location.pathname.includes('/blog') ? true : false;

              return (
                <div key={locales[lang].path} className={locales[lang].path}>
                  <Link
                    key={lang}
                    to={
                      isHome
                        ? localizedPath
                        : location.pathname.replace(
                            `/${i18n[locale].path}`,
                            localizedPath
                          )
                    }
                    className="dropdown-item"
                    onClick={() => setIsOpenLangSelect(false)}
                  >
                    {flags.nodes
                      .filter((node) => node.name === `flag-${lang}`)
                      .map((loc) => (
                        <img
                          key={lang}
                          src={loc.publicURL}
                          alt={locales[locale].langName}
                          className="flag"
                        />
                      ))}
                    {locales[lang].langName}
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

const langSwitcherCss = css`
  .button {
    border: 0;
    background-color: ${colors.blueLang};
    padding-left: 10px;
    padding-right: 40px;
  }

  .icon {
    padding-left: 5em;
  }

  .flag {
    width: 27px;
    margin-right: 10px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1453px) {
    .flag {
      width: 24px;
      margin-right: 1px;
    }
  }

  .dropdown-item {
    display: flex;
    align-items: center;
  }
`;
