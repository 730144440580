exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-aboutuspage-js": () => import("./../../../src/templates/aboutuspage.js" /* webpackChunkName: "component---src-templates-aboutuspage-js" */),
  "component---src-templates-apipage-js": () => import("./../../../src/templates/apipage.js" /* webpackChunkName: "component---src-templates-apipage-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blogpage-js": () => import("./../../../src/templates/blogpage.js" /* webpackChunkName: "component---src-templates-blogpage-js" */),
  "component---src-templates-cgvpage-js": () => import("./../../../src/templates/cgvpage.js" /* webpackChunkName: "component---src-templates-cgvpage-js" */),
  "component---src-templates-customizationpage-js": () => import("./../../../src/templates/customizationpage.js" /* webpackChunkName: "component---src-templates-customizationpage-js" */),
  "component---src-templates-dynamicpage-js": () => import("./../../../src/templates/dynamicpage.js" /* webpackChunkName: "component---src-templates-dynamicpage-js" */),
  "component---src-templates-fieldspage-js": () => import("./../../../src/templates/fieldspage.js" /* webpackChunkName: "component---src-templates-fieldspage-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-integration-js": () => import("./../../../src/templates/integration.js" /* webpackChunkName: "component---src-templates-integration-js" */),
  "component---src-templates-integration-tag-js": () => import("./../../../src/templates/integrationTag.js" /* webpackChunkName: "component---src-templates-integration-tag-js" */),
  "component---src-templates-integrationspage-js": () => import("./../../../src/templates/integrationspage.js" /* webpackChunkName: "component---src-templates-integrationspage-js" */),
  "component---src-templates-legalpage-js": () => import("./../../../src/templates/legalpage.js" /* webpackChunkName: "component---src-templates-legalpage-js" */),
  "component---src-templates-main-router-js": () => import("./../../../src/templates/mainRouter.js" /* webpackChunkName: "component---src-templates-main-router-js" */),
  "component---src-templates-partnershipspage-js": () => import("./../../../src/templates/partnershipspage.js" /* webpackChunkName: "component---src-templates-partnershipspage-js" */),
  "component---src-templates-pricepage-js": () => import("./../../../src/templates/pricepage.js" /* webpackChunkName: "component---src-templates-pricepage-js" */),
  "component---src-templates-servicespage-js": () => import("./../../../src/templates/servicespage.js" /* webpackChunkName: "component---src-templates-servicespage-js" */),
  "component---src-templates-solutionspage-js": () => import("./../../../src/templates/solutionspage.js" /* webpackChunkName: "component---src-templates-solutionspage-js" */),
  "component---src-templates-usecase-detailspage-js": () => import("./../../../src/templates/usecaseDetailspage.js" /* webpackChunkName: "component---src-templates-usecase-detailspage-js" */),
  "component---src-templates-usecasespage-js": () => import("./../../../src/templates/usecasespage.js" /* webpackChunkName: "component---src-templates-usecasespage-js" */)
}

