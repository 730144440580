import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"



const Image = ({ image, style = {}, wrapped = false }) => {
  if (image?.gatsbyImageData) {
    if (wrapped) {
      return (
        <div className="gatsby-image-wrapper">
          <GatsbyImage
            image={image?.gatsbyImageData}
            alt={image.alt}
            imgStyle={{ objectFit: "contain", ...style }}
            loading="lazy"
            decoding="async"
          />
        </div>
      )
    }
    return (
      <GatsbyImage
        image={image?.gatsbyImageData}
        alt={image.alt}
        imgStyle={{ objectFit: "contain", ...style }}
        loading="lazy"
        decoding="async"
      />
    );
  } else {
    return "";
  }
};

export default Image;
