import React from "react"
import { PrismicText, PrismicRichText } from "@prismicio/react";
import { css } from "@emotion/react"
import { colors } from "../styles/styles"
import { LocaleContext } from "./layout"
import LocalizedLink from "../components/localizedLink"
import LangSwitcher from "../components/langSwitcher"
import linkResolver from "../utils/linkResolver"
import Image from "../components/image"
import { isExternalLink, isDocumentLink } from "../utils/utils"
import CookieConsent from "react-cookie-consent"
import i18n from "../../config/i18n"
import Cookies from 'js-cookie';

import '../styles/footer/footer.scss'

const website = require("../../config/website")

export default function Footer({ allHeadersAndFooters }) {
  let { locale, location } = React.useContext(LocaleContext);
  if (allHeadersAndFooters === undefined) return false; // fix bug previews/ doesn't know the footer
  if (typeof allHeadersAndFooters === "string") return false; // fix bug previews/ doesn't know the footer
  locale = locale ? locale : "en-gb"; // fix: 404 doesnt know the locale
  let footerDataLang = allHeadersAndFooters.allPrismicFooter.nodes.filter(
    footerData => footerData.lang === locale
  );
  footerDataLang = footerDataLang[0].data;

  const isHomepage =
    location.pathname === "/" || location.pathname === `/${locale.slice(0, 2)}/`
      ? true
      : false;

  const event = new Date(allHeadersAndFooters.currentBuildDate?.currentDate)
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  
  return (
    <>
      <footer
        className="footer"
        css={footerCss}
        id="footer_main"
        style={isHomepage ? { backgroundColor: colors.blueBg } : {}}
      >
        <div className="container">
          <div className="content">
            <div className="columns">
              {footerDataLang.body.map(category => (
                <div key={category.primary.category_id} className="column footer_navlinks">
                  <nav>
                    <h6 className="title">
                      {category.primary.category_name.text}
                    </h6>
                    <ul>
                      {category.items.map(link => (
                        <li key={link.footer_element_id}>
                          {link.footer_element_link &&
                            link.footer_element_id !== "contactus" &&
                            isExternalLink(link.footer_element_link) && (
                              <a
                                href={link.footer_element_link.url}
                                target={link.footer_element_link.target}
                                rel="noopener noreferrer nofollow"
                              >
                                {link.footer_element_name}
                              </a>
                            )}
                          {link.footer_element_link &&
                            link.footer_element_id !== "contactus" &&
                            isDocumentLink(link.footer_element_link) && (
                              <LocalizedLink
                                to={link.footer_element_link.uid}
                              >
                                {link.footer_element_name}
                              </LocalizedLink>
                            )}
                          {link.footer_element_id === "contactus" && (
                            <a
                              href={`mailto:contact@monstock.net?subject=Contact Via Footer`}
                            >
                              {link.footer_element_name}
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              ))}
              <div className="column">
                <h6 className="title">
                  {footerDataLang.column_title.text}
                </h6>
                {footerDataLang.link_app_1 && (
                  <a
                    href={footerDataLang.link_app_1.url}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <figure className="image is-128x128 is-inline-block ml-0">
                      {footerDataLang.image_app_1 && (
                        <div id="googleplay-bt">
                        <Image
                          sharp={footerDataLang.image_app_1Sharp}
                          image={footerDataLang.image_app_1}
                        />
                        </div>
                      )}
                    </figure>
                  </a>
                )}
                {footerDataLang.link_app_2 && (
                  <a
                    href={footerDataLang.link_app_2.url}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <figure className="image is-128x128 is-inline-block ml-0">
                      {footerDataLang.image_app_2 && (
                        <div id="appstore-bt">
                        <Image
                          sharp={footerDataLang.image_app_2Sharp}
                          image={footerDataLang.image_app_2}
                        />
                        </div>
                      )}
                    </figure>
                  </a>
                )}
                <LangSwitcher flags={allHeadersAndFooters.allFile} />
              </div>
            </div>

            <div className="columns is-centered">
              <div className="column hasRichText has-text-centered">
                <hr />
                {footerDataLang.copyright && (
                  <PrismicRichText
                    field={footerDataLang.copyright.richText}
                  />
                )}
                {footerDataLang.last_update && (
                  <>
                    <p className="footer_last-mep-date">
                      {footerDataLang.last_update.text}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div css={gdpr}>
        <CookieConsent 
          location="bottom"
          enableDeclineButton
          buttonText={
            i18n[locale].gdprAccept
              ? i18n[locale].gdprAccept
              : i18n["en-gb"].gdprAccept
          }
          declineButtonText={
            i18n[locale].gdprDecline
              ? i18n[locale].gdprDecline
              : i18n["en-gb"].gdprDecline
          }
          cookieName="gdpr-cookies"
          disableStyles={true}
          containerClasses="gdpr"
          contentClasses="gdprContent"
          buttonClasses="button is-small is-rounded button__primary has-text-white mt-2"
          declineButtonClasses="button is-small is-rounded is-outlined is-link mt-2 mr-2"
          onAccept={() => {
            window['ga-disable-' + website.googleAnalyticsID] = false;
          }}
          onDecline={() => {
            window['ga-disable-' + website.googleAnalyticsID] = true;
          }}

        >
          {i18n[locale].gdprMessage
            ? i18n[locale].gdprMessage
            : i18n["en-gb"].gdprMessage} 
        </CookieConsent>
        {/* {(Cookies.get('gdpr_cookie')=== undefined) && 
        <div className="cookie_dialog" id="cookie" css={gdpr}>
          <div className="gdpr">
            <p className="cookie-title" id ="message-cookie">{i18n[locale].gdprMessage}</p>
            <button type="button" id="refuser" onClick={()=>{cookie_setter(false);disappear()} }>{i18n[locale].gdprDecline}</button>
            <button type="button" id="accepter" onClick={()=>{cookie_setter(true);disappear()} }>{i18n[locale].gdprAccept}</button>
          </div>
        </div>
        } */}
      </div>
    </>
  )
}


const footerCss = css`ok
  .footer__blue {
    background-color: ${colors.blueBg};
  }


  h6 {
    font-size: 1.125rem;
  }

  hr {
    background-color: ${colors.blueHr};
    height: 1px;
  }

  .content ul {
    font-size: 14px;
    margin-left: 0;
    line-height: 33px;
    list-style: none;

    a {
      color: ${colors.blue};
    }
  }

  .is-128x128 {
    height: 60px;
  }

  .dropdown-menu {
    max-height: 130px;
    overflow-y: scroll;
    width: 100%;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: ${colors.orangeGradient};
        border-radius: 5px;
    }

  }
`
const gdpr = css`
  .gdpr {
    position: fixed;
    bottom: 0;
    background: white;
    max-width: 305px;
    border-radius: 10px;
    padding: 20px;
    margin: 20px;
    z-index: 100;
    box-shadow: 0 12px 14px ${colors.blueLightOne};
  }
  #accepter,#refuser{
    
    border: none ;
    cursor : pointer;
    outline:none;
  
    border-radius: 9999px;
    margin: 0 5px;
  }
  #refuser{
    border: 2px solid #5769a4;
    padding: 8px 25px;
    color: #5769a4;
    background-color:transparent;
  }
  #refuser:hover{
    color:white;
    background-color: #5769a4;
  }
  #accepter{
    color:white;
    padding: 10px 25px;
    background: linear-gradient(270deg,#fd7543,#de4e4e);
  }
  .cookie-title{
    margin-bottom: 10px;
  }
  @keyframes disappear {
    0%{
        transform: translateX(0px);
    }

    100%{
        transform: translateX(-400px);
    }
    
}

.disappear{
    animation: disappear 0.5s ease-in-out forwards;
}
  `
